<template>
  <S2SForm title="Accounts">
    <v-data-table
      :headers="headers"
      :items="accounts"
      hide-default-footer
      class="elevation-1"
      :loading="loading"
      show-expand
      @item-expanded="onExpand"
      single-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container
            grid-list-xs
            v-if="selectedAccount && selectedAccount.id === item.id"
          >
            <v-layout row wrap>
              <v-flex
                xs12
                sm6
                px-2
                v-if="selectedAccount.accountType.key !== 'system'"
              >
                <v-text-field
                  @blur="saveName(selectedAccount, $event)"
                  @keydown.enter="saveName(selectedAccount, $event)"
                  :value="selectedAccount.name"
                  hide-details
                  label="Account Name"
                ></v-text-field>
              </v-flex>

              <v-flex
                xs12
                sm6
                px-2
                v-for="attribute in selectedAccount.accountType.attributes"
                :key="attribute.key"
                class="attribute-list"
              >
                <v-text-field
                  readonly
                  hide-details
                  v-if="
                    attribute.fieldType === 'text' ||
                    attribute.fieldType === 'numeric'
                  "
                  :name="attribute.key"
                  :required="attribute.required"
                  :label="attribute.label"
                  v-model="viewModel[attribute.key]"
                ></v-text-field>
                <v-text-field
                  readonly
                  hide-details
                  v-else-if="attribute.fieldType === 'password'"
                  type="password"
                  :name="attribute.key"
                  :required="attribute.required"
                  :label="attribute.label"
                  v-model="viewModel[attribute.key]"
                ></v-text-field>
                <v-select
                  readonly
                  hide-details
                  v-else-if="attribute.fieldType === 'option'"
                  :items="sanitizeLookup(attribute.validation)"
                  :label="attribute.label"
                  v-model="viewModel[attribute.key]"
                ></v-select>
              </v-flex>
              <v-flex xs12 class="text-sm-right">
                <!-- <v-btn color="accent" @click="onSave()">Save</v-btn> -->
              </v-flex>
            </v-layout>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils";

  export default Vue.extend({
    name: "Accounts",

    data: function () {
      return {
        Utils: Utils,
        viewModel: {},

        headers: [
          {
            text: "Account Type",
            value: "method",
          },
          {
            text: "Name",
            value: "name",
          },
        ],
      };
    },

    computed: {
      selectedAccount: function () {
        return this.$store.state.accounts.selectedAccount;
      },
      accounts: function () {
        return this.$store.state.accounts.accounts;
      },
      loading: function () {
        return this.$store.state.accounts.loading;
      },
    },

    watch: {
      selectedAccount: function () {
        const props = this.selectedAccount.properties;
        for (let item of props) {
          this.viewModel[item.key] = item.value;
        }
      },
    },

    mounted: function () {
      this.$store.dispatch("accounts/getAccounts");
    },

    methods: {
      onExpand({ item, value }) {
        if (value) this.$store.dispatch("accounts/fetchAccount", item.id);
      },
      buildEditModel() {
        const props = this.selectedAccount.properties;
        for (let item of props) {
          this.viewModel[item.key] = item.value;
        }
      },
      saveName(account, e) {
        if (e.currentTarget.value === account.name) return;
        const validAccount = {
          name: e.currentTarget.value,
          holder: account.holder,
          overdraftLimit: account.overdraftLimit,
          properties: account.properties,
          validationStatus: account.validationStatus,
        };

        delete validAccount.validationStatus.nextStates;
        this.$store.dispatch("accounts/updateAccountName", {
          accountId: account.id,
          account: validAccount,
        });
      },
      sanitizeLookup(lookupData) {
        const lookupJson = JSON.parse(lookupData);

        const lookups = [];
        for (let lookup in lookupJson) {
          lookups.push(lookupJson[lookup]);
        }
        return lookups;
      },
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .attribute-list > .v-text-field >>> .v-input__control > .v-input__slot:after,
  .attribute-list
    > .v-text-field
    >>> .v-input__control
    > .v-input__slot:before {
    border-style: none !important;
  }
</style>
